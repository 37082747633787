import React from 'react'

const PersistedElementContext = React.createContext({
  checkoutElement: () => {},
  checkinElement: () => {},
})

class PersistedElementProvider extends React.Component {
  constructor(props) {
    super(props)

    this._container = undefined

    this._pending_checkout = []

    this._checkout_map = {}
  }

  componentDidMount() {
    const { element_map, func_after_setup } = this.props

    // create a space in the "real" DOM for hidden elements to live
    const elem_container = document.createElement('section')

    this._container = elem_container

    elem_container.style = 'display:none;'
    document.body.appendChild(elem_container)

    // go through 'element_map' and render _each_ element to hidden Container
    for (const [elem_key, html_str] of Object.entries(element_map)) {
      const elem_wrapper = document.createElement('section')
      elem_wrapper.dataset.elemkey = elem_key
      elem_wrapper.innerHTML = html_str

      elem_container.appendChild(elem_wrapper)
    }

    // now that Container is set up with elements, can run the "pending" checkouts
    if (this._pending_checkout.length > 0) {
      for (const [key, callback, func_handle_recall] of this._pending_checkout) {
        this._func_checkout_element(key, callback, func_handle_recall)
      }
      // clear this
      this._pending_checkout = []
    }

    // now that DOM is "set up" run (optional) callback provided
    func_after_setup && func_after_setup()
  }

  _func_checkout_element = (key, callback, func_handle_recall) => {
    //= =======================================//
    if (this._container === undefined) {
      this._pending_checkout.push([key, callback, func_handle_recall])
      return
    }
    //= =======================================//

    //= =======================================//
    let checkout_info

    // handle case where ALREADY checked out

    if (key in this._checkout_map) {
      // console.warn('HANDLING MULTI CHECKOUT')

      checkout_info = this._checkout_map[key]

      // the 'callback' is just this same exact function signature
      // - it will be run "again" after element has been checked in
      const recall_callback = this._func_checkout_element.bind(this, key, callback, func_handle_recall)

      // signal to downstream Component that "recall" is happening (it will call callback func)
      checkout_info.func_handle_recall(recall_callback)
    } else {
      // at this point: element is NOT checked out

      //= =======================================//
      const lst_elems = this._container.querySelectorAll(`[data-elemkey=${key}]`)

      let elem

      if (lst_elems.length === 1) {
        elem = lst_elems[0]
      } else if (lst_elems.length === 0) {
        // allow this so that _downstream_ Component can handle
        console.warn(`missing element during checkout: key=${key}`)
      } else {
        // error because unpredictable if overlapping keys
        // - however: just log, don't crash things
        console.error(`unexpected multiple elements during checkout: key=${key}`)
      }
      //= =======================================//

      //= =======================================//
      // now: _remove_ from current place in DOM (caller will do something with literal element object)
      // (!! any concerns with: removal of event listeners, etc?)
      if (elem) {
        //= =======================================//
        checkout_info = {
          func_handle_recall,
        }

        this._checkout_map[key] = checkout_info
        //= =======================================//

        //= =======================================//
        this._container.removeChild(elem)

        callback(elem)
        //= =======================================//
      } else {
        console.warn('unexpected: elem = undefined (could not be checked out)')
      }
      //= =======================================//
    }
    //= =======================================//
  }

  _func_checkin_element = (key, elem) => {
    // console.info('CHECKIN ELEMENT')

    if (this._container === undefined) {
      console.warn('ignored "_func_checkin_element" (container === undefined)')
      return
    }

    this._container.appendChild(elem)

    // 'clear' flag that indicates checkout
    delete this._checkout_map[key]
  }

  render() {
    const { children } = this.props

    return (
      <PersistedElementContext.Provider value={{
        checkoutElement: this._func_checkout_element,
        checkinElement: this._func_checkin_element,
      }}
      >
        {children}
      </PersistedElementContext.Provider>
    )
  }
}

export default PersistedElementContext
export { PersistedElementProvider }
