import * as React from 'react'

import PersistedElementContext from '../context/PersistedElementContext'

const companyID = process.env.GATSBY_KLAVIYO_COMPANY_ID

const getScriptURL = function () {
  return `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${companyID}`
}

//= =======================================//
// from: https://summitxd.slack.com/archives/D66SQSFK8/p1634007852058400

const IFramedKlaviyoForm = (props) => {
  const srcDoc = `
<!DOCTYPE html>
  <body>
    <div class="klaviyo-form-${props.code}"></div>
    <script type="text/javascript" src="${getScriptURL()}"></script>
  </body>
</html>
`

  return (
    <iframe
      title="Klaviyo form"
      style={{ width: '100%', height: '500px', overflow: 'auto' }}
      srcDoc={srcDoc}
    />
  )
}
//= =======================================//

//= =======================================//
// improved version of: https://summitxd.slack.com/archives/D66SQSFK8/p1634003747056200

const WrappedAutoPositionedIFramedKlaviyoForm = ({
  code, wrapper_x_style, iframe_x_props, iframe_x_style,
}) => {
  wrapper_x_style = wrapper_x_style || {}
  iframe_x_props = iframe_x_props || {}
  iframe_x_style = iframe_x_style || {}

  const ref = React.useRef(null)

  // on Component mount, handle ref to DOM element
  React.useEffect(() => {
    if (ref.current) {
      const parent = ref.current.parentNode

      // NOTE: _changes_ parent style to get simple+quick "full cover" via absolute positioning
      // - (not ideal to mutate in this way but adding "position:relative" is usually low on side-effects)
      parent.style.position = 'relative'
    }
    // else: no ref to element? not expected

    return () => {

    }
  }, [])

  const srcDoc = `
<html>
  <body>
    <div class="klaviyo-form-${code}"></div>
    <script type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${companyID}"></script>
  </body>
</html>
`
  const jsx_out = (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        overflow: 'hidden',

        ...wrapper_x_style,
      }}
    >
      <iframe
        style={{
          width: '100%',
          height: '100%',
          overflow: 'auto',

          ...iframe_x_style,
        }}
        srcDoc={srcDoc}
        title="Meati"
        {...iframe_x_props}
      />
    </div>
  )

  return jsx_out
}
//= =======================================//

//= =======================================//
class PreloadedKlaviyoFormInner extends React.Component {
  constructor(props) {
    super(props)

    this._elem = undefined

    // create Element "key" (used to uniquely ID DOM node) from Klaviyo "code" (the value used by Klaviyo to ID the form)
    this._elem_key = `kvform_${props.code}`
  }

  _set_ref = (elem) => {
    this._elem = elem
  }

  _func_checkout_element = (func_set_elem) => {
    this.props.ctx.checkoutElement(this._elem_key, func_set_elem, this._func_handle_recall)
  }

  _func_checkin_element = (elem) => {
    if (this._elem) {
      const ext_elem = this._elem.firstChild

      if (ext_elem) {
        this._elem.removeChild(ext_elem)
        this.props.ctx.checkinElement(this._elem_key, ext_elem)
      } else {
        console.warn('_func_checkin_element ignored (no ref to ext_elem)')
      }
    } else {
      console.warn('_func_checkin_element ignored (no ref to this._elem)')
    }
  }

  _func_handle_recall = (callback) => {
    // console.warn('HANDLING RECALL')

    this._func_checkin_element()

    callback()
  }

  componentDidMount() {
    this._func_checkout_element((ext_elem) => {
      if (this._elem) {
        this._elem.appendChild(ext_elem)
      }
    })
  }

  componentWillUnmount() {
    this._func_checkin_element()
  }

  render() {
    return (
      <section ref={this._set_ref} />
    )
  }
}

const PreloadedKlaviyoFormOuter = ({ code }) => {
  const func_render = (ctx) => (
    <PreloadedKlaviyoFormInner code={code} ctx={ctx} />
  )

  return (
    <PersistedElementContext.Consumer>
      {func_render}
    </PersistedElementContext.Consumer>
  )
}
//= =======================================//

//= =======================================//
const KlaviyoForm = function ({
  method, code, wrapper_x_style, iframe_x_props, iframe_x_style,
}) {
  method = method || 'preloaded'

  let jsx_out

  if (method === 'iframe_simplified') {
    jsx_out = <IFramedKlaviyoForm {...{ code }} />
  } else if (method === 'iframe_wrapped') {
    jsx_out = (
      <WrappedAutoPositionedIFramedKlaviyoForm {...{
        code, wrapper_x_style, iframe_x_props, iframe_x_style,
      }}
      />
    )
  } else if (method === 'preloaded') {
    jsx_out = <PreloadedKlaviyoFormOuter {...{ code }} />
  }

  return jsx_out
}
//= =======================================//

export default KlaviyoForm
export { getScriptURL }
